// Ionic Variables and Theming. For more info, please see:
// http://ionicframework.com/docs/theming/

/** Ionic CSS Variables **/
:root {
    /** primary **/
    --ion-color-primary: #00155a;
    --ion-color-primary-rgb: 0, 21, 90;
    --ion-color-primary-contrast: #ffffff;
    --ion-color-primary-contrast-rgb: 255, 255, 255;
    --ion-color-primary-shade: #00124f;
    --ion-color-primary-tint: #1a2c6b;

    /** secondary **/
    --ion-color-secondary: #fe5716;
    --ion-color-secondary-rgb: 254, 87, 22;
    --ion-color-secondary-contrast: #000000;
    --ion-color-secondary-contrast-rgb: 0, 0, 0;
    --ion-color-secondary-shade: #e04d13;
    --ion-color-secondary-tint: #fe682d;

    /** tertiary **/
    --ion-color-tertiary: #1089ff;
    --ion-color-tertiary-rgb: 16, 137, 255;
    --ion-color-tertiary-contrast: #ffffff;
    --ion-color-tertiary-contrast-rgb: 255, 255, 255;
    --ion-color-tertiary-shade: #0e79e0;
    --ion-color-tertiary-tint: #2895ff;

    /** success **/
    --ion-color-success: #4f9e30;
    --ion-color-success-rgb: 79, 158, 48;
    --ion-color-success-contrast: #ffffff;
    --ion-color-success-contrast-rgb: 255, 255, 255;
    --ion-color-success-shade: #468b2a;
    --ion-color-success-tint: #61a845;

    /** warning **/
    --ion-color-warning: #ff861d;
    --ion-color-warning-rgb: 255, 134, 29;
    --ion-color-warning-contrast: #ffffff;
    --ion-color-warning-contrast-rgb: 0, 0, 0;
    --ion-color-warning-shade: #e0761a;
    --ion-color-warning-tint: #ff9234;

    /** danger **/
    --ion-color-danger: #e23737;
    --ion-color-danger-rgb: 226, 55, 55;
    --ion-color-danger-contrast: #ffffff;
    --ion-color-danger-contrast-rgb: 255, 255, 255;
    --ion-color-danger-shade: #c73030;
    --ion-color-danger-tint: #e54b4b;

    /** dark **/
    --ion-color-dark: #333333;
    --ion-color-dark-rgb: 51, 51, 51;
    --ion-color-dark-contrast: #ffffff;
    --ion-color-dark-contrast-rgb: 255, 255, 255;
    --ion-color-dark-shade: #35373e;
    --ion-color-dark-tint: #505259;

    /** medium **/
    --ion-color-medium: #666666;
    --ion-color-medium-rgb: 102, 102, 102;
    --ion-color-medium-contrast: #ffffff;
    --ion-color-medium-contrast-rgb: 255, 255, 255;
    --ion-color-medium-shade: #2d2d2d;
    --ion-color-medium-tint: #474747;

    /** light **/
    --ion-color-light: #E0E0E0;
    --ion-color-light-rgb: 224, 224, 224;
    --ion-color-light-contrast: #000000;
    --ion-color-light-contrast-rgb: 0, 0, 0;
    --ion-color-light-shade: #c5c5c5;
    --ion-color-light-tint: #e3e3e3;

    /** text **/
    --ion-text-color: var(--ion-color-primary);

    /** item **/
    --ion-item-color: var(--ion-color-primary);
}
