/**
* Custom CSS Variables for configuring theme appearance / look
* Contains only our own variables. Please use `ionic.variables.scss` if you need to tweak Ionic default/global component style.
**/
:root {
    // ~ APP
    // ====================================================================== >
    --theme-app-background: #e9f1f9;

    // ~ LOGO
    // ====================================================================== >
    --theme-toolbar-logo-width: 98px;

    // ~ FONT
    // ====================================================================== >
    --theme-font-family: 'EDF2020';
    --theme-font-color: var(--ion-color-primary);
    --theme-font-color-rgb: var(--ion-color-primary-rgb);
    --theme-font-color-dark: var(--ion-color-primary);

    --theme-title-font-color: var(--ion-color-primary);

    // ~ HEADER
    // ====================================================================== >
    --theme-background-color-header: white;

    --theme-background-color-icon-info: white;
    --theme-color-icon-info : var(--ion-color-primary);

    --theme-emphasis-font-weight: 500;
    --theme-emphasis-font-color: white;

    // ~ INPUT
    // ====================================================================== >
    --theme-input-border-color: var(--ion-color-secondary);
    --theme-input-placeholder-color: var(--ion-color-dark-tint);
    --theme-input-placeholder-icon-color: var(--ion-color-secondary);
    --theme-input-placeholder-style: italic;
    --theme-input-placeholder-opacity: none;

    // ~ TOGGLE
    // ====================================================================== >
    --theme-toggle-background-checked: var(--ion-color-secondary);

    // ~ CARDS
    // ====================================================================== >
    --theme-cards-border-selected: var(--ion-color-secondary);

    // ~ STEP INDICATORS
    // ====================================================================== >
    --theme-step-indicator-color: var(--ion-color-primary);
    --theme-step-indicator-background: white;
    --theme-step-indicator-border: var(--ion-color-primary);

    --theme-step-indicator-color-selected: white;
    --theme-step-indicator-background-selected: var(--ion-color-secondary);
    --theme-step-indicator-border-selected: var(--ion-color-secondary);

    --theme-step-indicator-separator: var(--ion-color-primary);
    --theme-step-indicator-separator-complete: var(--ion-color-secondary);

    // ~ MAP MARKER
    // ====================================================================== >
    --theme-map-marker-available-color: #75BF44;
    --theme-map-marker-busy-color: #00A6D4;
    --theme-map-marker-unusable-color: #ED2440;
    --theme-map-marker-unknown-color: #C6C6C5;

    // ~ HOME - ALERT
    // ====================================================================== >
    --theme-alert-calc-shift: 360px
}
